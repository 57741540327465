import React from "react";
import ReactDOM from 'react-dom/client';
import "./index.css";
import App from "./web.routes";
import reportWebVitals from "./reportWebVitals";
import Theme from "./theme/theme-default";
import { ThemeProvider } from "@mui/material/styles";
import store from "./redux/store/store";
import { Provider } from "react-redux";
import { StyledEngineProvider } from "@mui/material/styles";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={Theme}>
        <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
import React, { useEffect, useState } from "react";
import ChitralekhaImg from "../../img/chiralekha-bg.png";
import GlobalStyles from "../../styles/GlobalStyles";
import CustomBanner from "../components/common/CustomBanner";
import getTranscriptReport from "../../redux/actions/api/chitralekha/FetchTranscriptReport";
import getTranslationReport from "../../redux/actions/api/chitralekha/FetchTranslationReport";
import getVoiceoverReport from "../../redux/actions/api/chitralekha/FetchVoiceoverReport";
import APITransport from "../../redux/actions/apitransport/apitransport";
import { useDispatch, useSelector } from "react-redux";
import TranscriptionChart from "../components/chitralekha/TranscriptionChart";
import TranslationChart from "../components/chitralekha/TranslationChart";
import VoiceoverChart from "../components/chitralekha/VoiceoverChart";

const Chitralekha = () => {
  const classes = GlobalStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const transcriptReport = useSelector(
    (state) => state.getTranscriptReport.data
  );
  const translationReport = useSelector(
    (state) => state.getTranslationReport.data
  );
  const voiceoverReport = useSelector((state) => state.getVoiceoverReport.data);

  useEffect(() => {
    setLoading(true);

    const transcriptObj = new getTranscriptReport();
    dispatch(APITransport(transcriptObj));

    const translationObj = new getTranslationReport();
    dispatch(APITransport(translationObj));

    const voiceoverObj = new getVoiceoverReport();
    dispatch(APITransport(voiceoverObj));

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [transcriptReport, translationReport, voiceoverReport]);

  const buttonGroup = [
    {
      title: "Try Demo",
      className: classes.demoBtn,
      onClick: () =>
        window.open("https://chitralekha.ai4bharat.org/", "_blank"),
    },
    {
      title: "Codebase",
      className: classes.demoBtn,
      onClick: () =>
        window.open("https://github.com/AI4Bharat/Chitralekha", "_blank"),
    },
    {
      title: "Tutorial",
      className: classes.demoBtn,
      onClick: () =>
        window.open("https://github.com/AI4Bharat/Chitralekha/wiki", "_blank"),
    },
    {
      title: "Introduction Video",
      className: classes.demoBtn,
      onClick: () =>
        window.open("https://www.youtube.com/watch?v=wmtYXKupumA", "_blank"),
    },
    {
      title: "API Specs",
      className: classes.demoBtn,
      onClick: () =>
        window.open(
          "https://backend.chitralekha.ai4bharat.org/swagger/",
          "_blank"
        ),
    },
  ];

  const description = (
    <>
      <b>Chitralekha</b> is an <b>open source</b> platform tool for video
      subtitling across various Indic languages, using ML model support (ASR for
      Transcription and NMT for Translation) Chitralekha offers support for
      multiple input sources (Ex : Youtube, local etc) and transcription
      generation process (Ex : Models, Source captions, Custom subtitle files
      etc).
    </>
  );

  return (
    <>
      <CustomBanner
        heading={"Chitralekha"}
        description={description}
        buttonGroupConfig={buttonGroup}
        imgSource={ChitralekhaImg}
      />

      <TranscriptionChart loading={loading} chartData={transcriptReport} />
      <TranslationChart loading={loading} chartData={translationReport} />
      <VoiceoverChart loading={loading} chartData={voiceoverReport} />
    </>
  );
};

export default Chitralekha;

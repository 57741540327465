import API from "../../../api";
import ENDPOINTS from "../../../../config/apiendpoint";
import C from "../../../constants";

export default class FetchDatasetChartAPI extends API {
  constructor(dataType, value, criterions, timeout = 2000) {
    super("POST", timeout, false);
    this.dataType = dataType;
    this.criterions = criterions;
    this.value = value;
    this.type = C.FETCH_DATASET;
    this.endpoint = `${super.apiEndPointULCA()}${ENDPOINTS.getDatasetChart}`;
  }

  processResponse(res) {
    super.processResponse(res);
    if (res) {
      this.fetchAnalyticsData = res;
    }
  }

  apiEndPoint() {
    return this.endpoint;
  }

  getBody() {
    return {
      type: this.dataType,
      criterions: this.criterions ? this.criterions : null,
      groupby: this.value ? [{ field: this.value, value: null }] : null,
    };
  }

  getHeaders() {
    this.headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return this.headers;
  }

  getPayload() {
    return this.fetchAnalyticsData;
  }
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IndicGlossary from "../../img/indic-glossary-explorer.png";
import FetchDomainAndCollectionSourceData from "../../redux/actions/api/IndicGlossaryExplorer/DomainAndCollectionSource";
import GlobalStyles from "../../styles/GlobalStyles";
import CustomBanner from "../components/common/CustomBanner";
import DomainChart from "../components/IndicGlossaryExplorer/DomainChart";
import APITransport from "../../redux/actions/apitransport/apitransport";
import CollectionSourceChart from "../components/IndicGlossaryExplorer/CollectionSourceChart";
import FetchLanguageCountData from "../../redux/actions/api/IndicGlossaryExplorer/LanguageCount";
import LanguageChart from "../components/IndicGlossaryExplorer/LanguageChart";
import getLanguages from "../../redux/actions/api/IndicGlossaryExplorer/getLanguages";

const IndicGlossaryExplorer = () => {
  const classes = GlobalStyles();
  const dispatch = useDispatch();

  const [domainChartData, setDomainChartData] = useState();
  const [collectionSourceChartData, setCollectionSourceChartData] = useState();
  const [langCountData, setLangCountData] = useState();
  const [loadingLanguageChart, setLoadingLanguageChart] = useState(true)
  const [loadingChart, setLoadingChart] = useState(true)

  const LanguageChartData = useSelector(
    (state) => state.getIndicGlossaryExplorerLanguageCountData.data
  );
  const chartData = useSelector(
    (state) => state.getDomainAndCollectionSourceData.data
  );

  useEffect(()=>{
    setLoadingChart(true);
    setLoadingLanguageChart(true);
    fetchAllLanguages();
    // fetchLanguageCountChartData();
    fetchLangCountChartData();
    fetchDomainCollectionChartData();   
  },[]);

  useEffect(()=>{
    if(langCountData){
      setLoadingLanguageChart(false)
    }

    if(domainChartData || collectionSourceChartData){
      setLoadingChart(false);
    }

  },[langCountData, domainChartData, collectionSourceChartData])

  const fetchAllLanguages = () => {
    const langApiObj = new getLanguages();
    dispatch(APITransport(langApiObj));
  }

  const fetchLangCountChartData = () => {
    let apiObj = new FetchLanguageCountData("en");
    dispatch(APITransport(apiObj));
  }

  const fetchDomainCollectionChartData = () => {
    let apiObj = new FetchDomainAndCollectionSourceData();
    dispatch(APITransport(apiObj));
  };

  const fetchLanguageCountChartData = (srcLang) => {
    const languageData = getSortedAndFilteredLangCountDataBySrcLang(srcLang)
    languageData[0]?.languages?.sort((a, b) => b.count - a.count);
    setLangCountData(languageData[0]);
  };

  const getSortedAndFilteredLangCountDataBySrcLang = (srcLang) => {
    const modifiedLanguageChartData = [];
    let totalCount = 0;
    LanguageChartData?.languages?.map((el,i)=>{
      if(el.srcLanguage === srcLang){
        modifiedLanguageChartData.push(el);
        totalCount = totalCount + el.count;
      }
    });

    return [{languages: modifiedLanguageChartData, totalCount: totalCount}];

  }

  useEffect(() => {
    chartData[0]?.domains?.sort((a, b) => b.count - a.count);
    chartData[1]?.CollectionSources?.sort((a, b) => b.count - a.count);
    const languageData = getSortedAndFilteredLangCountDataBySrcLang("en")
    languageData[0]?.languages?.sort((a, b) => b.count - a.count);
    setDomainChartData(chartData[0]);
    setCollectionSourceChartData(chartData[1]);
    setLangCountData(languageData[0]);
  }, [LanguageChartData, chartData]);

  const buttonGroup = [
    {
      title: "Try Demo",
      className: classes.demoBtn,
      onClick: () => window.open("https://glossary.ai4bharat.org/", "_blank"),
    },
    {
      title: "Codebase",
      className: classes.demoBtn,
      onClick: () =>
        window.open(
          "https://github.com/AI4Bharat/Indic-Glossary-Explorer",
          "_blank"
        ),
    },
    {
      title: "Tutorial",
      className: classes.demoBtn,
      onClick: () =>
        window.open(
          "https://github.com/AI4Bharat/Indic-Glossary-Explorer/wiki",
          "_blank"
        ),
    },
    {
      title: "Introduction Video",
      className: classes.demoBtn,
      onClick: () =>
        window.open("https://www.youtube.com/watch?v=MPtezE6KDvk", "_blank"),
    },
    {
      title: "API Specs",
      className: classes.demoBtn,
      onClick: () =>
        window.open(
          "https://app.swaggerhub.com/apis/ai4bharat-iitm/indic-glossary-explorer/1.0.0",
          "_blank"
        ),
    },
  ];

  const description = (
    <>
      <b>Indic Glossary Explorer</b> is an <b>open source</b> service to store
      and explore relevant Indic glossary which are domain specific. The service
      also provides the capabities for glossary contribution (individual/batch).
    </>
  );

  useEffect(()=>{
    const languageData = getSortedAndFilteredLangCountDataBySrcLang("en")
    languageData[0]?.languages?.sort((a, b) => b.count - a.count);
    chartData[0]?.domains?.sort((a, b) => b.count - a.count);
    chartData[1]?.CollectionSources?.sort((a, b) => b.count - a.count);
    setLangCountData(languageData[0]);
    setDomainChartData(chartData[0]);
    setCollectionSourceChartData(chartData[1]);
  }, [chartData, LanguageChartData])

  return (
    <>
      <CustomBanner
        heading={"Indic Glossary Explorer"}
        description={description}
        buttonGroupConfig={buttonGroup}
        imgSource={IndicGlossary}
      />
      <LanguageChart incomingData={langCountData} totalCount={LanguageChartData.totalcount} loadingChart={loadingLanguageChart} onLanguageChange={fetchLanguageCountChartData} />
      <DomainChart sourceData={domainChartData} loadingChart={loadingChart} />
      <CollectionSourceChart sourceData={collectionSourceChartData} loadingChart={loadingChart} />
    </>
  );
};

export default IndicGlossaryExplorer;

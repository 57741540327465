// CustomizedButton
import { Button } from '@mui/material';
import React from 'react';

const CustomizedButton = (props) => {
    return(
        <Button 
            onClick={props.onClick}
            variant= {props.variant ? props.variant : "contained" }
            {...props}
        >{props.title}</Button>
    )
}

export default CustomizedButton;
import { Box, Typography } from "@mui/material";
import React from "react";
import CustomButtonGroup from "./ButtonGroup";
import GlobalStyles from "../../../styles/GlobalStyles";

const CustomBanner = ({
  heading,
  description,
  buttonGroupConfig,
  imgSource,
}) => {
  const classes = GlobalStyles();

  return (
    <>
      <Box display={{ xs: 'block', md: 'none' }} sx={{ textAlign: 'center', marginBottom: -2, marginTop: 1 }}>
        <img className="aboutImg" style={{ width: '100%' }} src={imgSource} />
      </Box>
      <Box className={classes.section} marginTop={{ xs: 2, md: 0 }}>
        <Box className={classes.textBox}>
          <Typography variant="h1" className={classes.heading}>
            {heading}
          </Typography>
          <Typography className={classes.description}>{description}</Typography>
          <Box>
            <CustomButtonGroup buttonGroup={buttonGroupConfig} />
          </Box>
        </Box>
        <Box display={{ xs: 'none', md: 'inherit' }}>
          <img src={imgSource} className="aboutImg" />
        </Box>
      </Box>
    </>

  );
};

export default CustomBanner;

export default {
    getOrganizations:"/organizations/",

    //ULCA APIs
    getDatasetChart: "ulca/data-metric/v0/store/ai4b",
    getModelChart: "ulca/data-metric/v0/store/ai4bmodels",

    //Glossary APIs
    getIndicGlossaryExplorerDomainAndCollectionSource: "glossary-explorer/v1/count",
    getIndicGlossaryExplorerLanguageCount: "glossary-explorer/v1/lang_count",
    getLanguage: "glossary-explorer/v1/lang",

    // Anuvaad APIs
    getAllSupportedLangList: "anuvaad-metrics/anuvaad-data/languages",
    getTranslatedAndVerifiedSetenceCount: "anuvaad-metrics/anuvaad-data/verified_count",
    getLangCount: "anuvaad-metrics/anuvaad-data/lang_count",
    getDocumentCountPerOrg: "anuvaad-metrics/anuvaad-data/doc_count",

    //Chitralekha APIs
    getTranscriptReport: "transcript/get_report_transcript/",
    getTranslationReport: "translation/get_report_translation/",
    getVoiceoverReport: "voiceover/get_report_voiceover/",
  };
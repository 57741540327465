import React, { useState } from "react";
import { AppBar, Box, Toolbar } from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import HeaderStyles from "../../../styles/HeaderStyles";
import toolsLogo from "../../../img/tools-logo.png";
import { Drawer, IconButton } from '@mui/material';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeft from '@material-ui/icons/ChevronLeft'
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';

const Header = () => {
  const classes = HeaderStyles();
  const navigate = useNavigate();

  const [showDrawer, setShowDrawer] = useState(false)

  const headerMenuArr = [
    {
      name: "Home",
      id: "/",
      onclick: () => onHeaderMenuClick("/"),
      isActive: () => getActiveRoute("/"),
    },
    {
      name: "Anuvaad",
      id: "/anuvaad",
      onclick: () => onHeaderMenuClick("/anuvaad"),
      isActive: () => getActiveRoute("/anuvaad"),
    },
    {
      name: "Shoonya",
      id: "/shoonya",
      onclick: () => onHeaderMenuClick("/shoonya"),
      isActive: () => getActiveRoute("/shoonya"),
    },
    {
      name: "Chitralekha",
      id: "/chitralekha",
      onclick: () => onHeaderMenuClick("/chitralekha"),
      isActive: () => getActiveRoute("/chitralekha"),
    },
    {
      name: "Indic Glossary Explorer",
      id: "/indic-glossary-explorer",
      onclick: () => onHeaderMenuClick("/indic-glossary-explorer"),
      isActive: () => getActiveRoute("/indic-glossary-explorer"),
    },
  ];

  const onHeaderMenuClick = (id) => {
    // handleCloseNavMenu();
    navigate(id);
  };

  const getActiveRoute = (id) => {
    // return location.pathname === id
  };

  const handleDrawerToggle = () => {
    setShowDrawer(!showDrawer)
  }

  return (
    <AppBar
      position='sticky'
      sx={{
        background: "#FFFFFF",
        margin:"0px",
      }}
    >
      <Box
        display={{ xs: 'none', md: 'flex' }}
        alignItems="center"
        paddingLeft={30}
        paddingRight={30}
        justifyContent="space-between"
        sx={{height:"65px"}}
      >
        <Box className={classes.toolsLogo} display="flex">
          <img src={toolsLogo} className={classes.toolsLogoImg} />
        </Box>
        <Box display="flex" alignItems="center" margin="10px">
          <Box className={classes.ai4bharatLogo} display="flex">
            <p style={{ margin: "0 10px 0 4px" }}>AI4BHARAT</p>
          </Box>
          <Box className={classes.ekStepLogo} display="flex">
            <p>|&nbsp;&nbsp;EKSTEP</p>
          </Box>
        </Box>
      </Box>

      <Box
        display={{ xs: 'flex', md: 'none' }}
        alignItems="center"
        paddingLeft={5}
        paddingRight={5}
        justifyContent="space-between"
      >
        <Box className={classes.toolsLogo} display="flex" textAlign='center' alignItems='center'>
          <img src={toolsLogo} className={classes.toolsLogoImg} />
        </Box>
        <Box>
          <IconButton onClick={() => handleDrawerToggle()}>
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor={"right"}
            open={showDrawer}
            onClose={() => handleDrawerToggle()}
          >
            <Box display="flex" alignItems="center" sx={{padding: 1}}>
              <Box>
                <IconButton onClick={() => handleDrawerToggle()}>
                  <ChevronLeft />
                </IconButton>
              </Box>
              <Box className={classes.ai4bharatLogo} display="flex">
                <p style={{ margin: "0 10px 0 4px" }}>AI4BHARAT</p>
              </Box>
              <Box className={classes.ekStepLogo} display="flex">
                <p>|&nbsp;&nbsp;EKSTEP</p>
              </Box>
            </Box>
            <Box sx={{marginLeft: 3}}>
              {headerMenuArr?.map((page) => (
                <Box sx={{marginTop: 3}}>
                  <NavLink
                    key={page.id}
                    to={page.id}
                    className={({ isActive }) =>
                      isActive ? classes.highlightedMenu : classes.headerMenu
                    }
                    onClick={() => handleDrawerToggle()}
                    activeClassName={classes.highlightedMenu}
                    end
                  >
                    {page.name}
                  </NavLink>
                </Box>
              ))}
            </Box>

          </Drawer>
        </Box>
        {/* <Box display="flex" alignItems="center" margin="10px">
          
        </Box> */}
      </Box>
      <Box display={{ xs: 'none', md: 'flex' }}>
        <Toolbar className={classes.toolbar}>
          <Box className={classes.navContainer}>
            {headerMenuArr?.map((page) => (
              <NavLink
                key={page.id}
                to={page.id}
                className={({ isActive }) =>
                  isActive ? classes.highlightedMenu : classes.headerMenu
                }
                activeClassName={classes.highlightedMenu}
                end
              >
                {page.name}
              </NavLink>
            ))}
          </Box>
        </Toolbar>
      </Box>

    </AppBar>
  );
};

export default Header;

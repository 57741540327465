import { AppBar, Box, Grid, Typography } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React, { useState } from "react";
import Home from "./ui/containers/Home";
import Anuvaad from "./ui/containers/Anuvaad";
import Shoonya from "./ui/containers/Shoonya";
import Chitralekha from "./ui/containers/Chitralekha";
import IndicGlossaryExplorer from "./ui/containers/IndicGlossaryExplorer";
import Header from "./ui/components/common/Header";
// import Header from "./components/Header";
// import Home from "./components/Home";
// import Anuvaad from "./components/Anuvaad";
// import Shoonya from "./components/Shoonya";
// import Chitralekha from "./components/Chitralekha";
// import IndicGlossaryExplorer from "./components/IndicGlossaryExplorer";

const TabPanel = (props) => {
  const { children, value, index, ...other  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    style: {
      textTransform: "none",
      color: "#3a3a3a",
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "Roboto",
    },
  };
};

const App = (props) => {
  const [value, setValue] = useState(0);

  const {component} = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Header />
      <Grid sx={{overflowX: 'hidden'}}>
        {component}
      </Grid>
      {/* <div style={{ backgroundColor: "#f3f3f3" }}>
        <div className="container">
          <AppBar position="static" style={{ backgroundColor: "#f3f3f3", border: "none", boxShadow: "none" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="standard"
              aria-label="simple tabs example"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "rgb(243, 156, 18)"
                }
              }}
            >
              <Tab label="Home" {...a11yProps(0)} />
              <Tab label="Anuvaad" {...a11yProps(1)} />
              <Tab label="Shoonya" {...a11yProps(2)} />
              <Tab label="Chitralekha" {...a11yProps(3)} />
              <Tab label="Indic Glossary Explorer" {...a11yProps(4)} />
            </Tabs>
          </AppBar>
        </div>
      </div>

      <TabPanel value={value} index={0}>
        <Home />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Anuvaad />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Shoonya />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Chitralekha />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <IndicGlossaryExplorer />
      </TabPanel> */}
    </>
  );
};
export default App;

export const getParallelCorpusConfig = (
  page,
  selectedOption,
  dataSet,
  fetchChartData,
  setTitle,
  setAxisValue,
  sourceLanguage,
  event,
  filter,
  selectedLanguageName,
  dataValue,
  filterValue
) => {
  if (page === 0) {
    setTitle("Number of parallel sentences per language with ");
    selectedOption.value !== dataSet.value &&
      fetchChartData(dataSet.value, "", [
        { field: "sourceLanguage", value: sourceLanguage.value },
      ]);
    setAxisValue({ xAxis: "Languages", yAxis: "Count" });
  } else if (page === 1) {
    setTitle(
      `${sourceLanguage.label}-${
        selectedLanguageName
          ? selectedLanguageName
          : event && event.hasOwnProperty("label") && event.label
      }  parallel sentences - Grouped by ${
        filter === "domains"
          ? "Domain"
          : filter === "source"
          ? "Source"
          : filter === "collectionMethod_collectionDescriptions"
          ? "Collection Method"
          : "Domain"
      }`
    );
    setAxisValue({
      yAxis: "Count",
      xAxis:
        filter === "domains"
          ? "Domain"
          : filter === "source"
          ? "Source"
          : filter === "collectionMethod_collectionDescriptions"
          ? "Collection Method"
          : "Domain",
    });
  } else if (page === 2) {
    setTitle(
      `${sourceLanguage.label}-${selectedLanguageName} parallel sentences ${
        filterValue === "primarySubmitterName" ? "by" : "of"
      }  ${event.label ? event.label : dataValue} - Grouped by ${
        filter === "domains"
          ? "Domain"
          : filter === "collectionMethod_collectionDescriptions"
          ? "Collection Method"
          : "Domain"
      }`
    );
    setAxisValue({
      yAxis: "Count",
      xAxis:
        filter === "domains"
          ? "Domain"
          : filter === "collectionMethod_collectionDescriptions"
          ? "Collection Method"
          : "Domain",
    });
  }
};

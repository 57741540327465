export const getMonolingualCorpus = (
  page,
  selectedOption,
  dataSet,
  fetchChartData,
  setTitle,
  setAxisValue,
  sourceLanguage,
  event,
  filter,
  selectedLanguageName,
  dataValue,
  filterValue
) => {
  if (page === 0) {
    selectedOption.value !== dataSet.value &&
      fetchChartData(dataSet.value, "", [
        { field: "sourceLanguage", value: null },
      ]);
    setTitle("Number of sentences per language");

    setAxisValue({ xAxis: "Languages", yAxis: "Count" });
  } else if (page === 1) {
    setTitle(
      `Number of sentences in ${
        selectedLanguageName
          ? selectedLanguageName
          : event && event.hasOwnProperty("label") && event.label
      } - Grouped by ${
        filter === "domains"
          ? "Domain"
          : filter === "source"
          ? "Source"
          : filter === "collectionMethod_collectionDescriptions"
          ? "Collection Method"
          : "Domain"
      }`
    );
    setAxisValue({
      yAxis: "Count",
      xAxis:
        filter === "domains"
          ? "Domain"
          : filter === "source"
          ? "Source"
          : filter === "collectionMethod_collectionDescriptions"
          ? "Collection Method"
          : "Domain",
    });
  } else if (page === 2) {
    setTitle(
      `Number of sentences in ${selectedLanguageName}${
        filterValue === "primarySubmitterName" ? " by" : " of"
      } ${event.label ? event.label : dataValue}  - Grouped by ${
        filter === "domains"
          ? "Domain"
          : filter === "collectionMethod_collectionDescriptions"
          ? "Collection Method"
          : "Domain"
      }`
    );
    setAxisValue({
      yAxis: "Count",
      xAxis:
        filter === "domains"
          ? "Domain"
          : filter === "collectionMethod_collectionDescriptions"
          ? "Collection Method"
          : "Domain",
    });
  }
};

import { HashRouter, Switch, Route, Routes } from "react-router-dom";
import history from "./web.history";
import Layout from "./Layout";
import Home from "./ui/containers/Home";
import Anuvaad from "./ui/containers/Anuvaad";
import Shoonya from "./ui/containers/Shoonya";
import Chitralekha from "./ui/containers/Chitralekha";
import IndicGlossaryExplorer from "./ui/containers/IndicGlossaryExplorer";
import Header from "./ui/components/common/Header";

export default function App() {
  return (
    <HashRouter>
      
      <Routes>
        {/* <Route path="/" component={()=>{<h1>hello</h1>}} /> */}
        {/* <Header /> */}
        {/* <Route exact path="/" component={<Layout />} /> */}
        <Route path="/" element={<Layout component={<Home />} />} />
        <Route path="/anuvaad" element={<Layout component={<Anuvaad />} />} />
        <Route path="/shoonya" element={<Layout component={<Shoonya />} />} />
        <Route path="/chitralekha" element={<Layout component={<Chitralekha />} />} />
        <Route path="/indic-glossary-explorer" element={<Layout component={<IndicGlossaryExplorer />} />} />
        {/* <TabPanel value={value} index={0}>
        <Home />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Anuvaad />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Shoonya />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Chitralekha />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <IndicGlossaryExplorer />
      </TabPanel> */}
      </Routes>
    </HashRouter>
  );
}

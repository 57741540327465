import apiStatus from "./apistatus/apistatus";
import getAnalyticsData from "./Shoonya/AnalyticsData";
import getMetaAnalyticsData from "./Shoonya/MetaAnalyticsData";
import getDatasets from "./ULCA/Datasets";
import getModels from "./ULCA/Models";
import getDomainAndCollectionSourceData from "./IndicGlossaryExplorer/DomainAndCollectionSource";
import getIndicGlossaryExplorerLanguageCountData from "./IndicGlossaryExplorer/LanguageCount";
import getAllLanguages from "./IndicGlossaryExplorer/getLanguages";
import getAnuvaadSupportedLanguages from "./Anuvaad/getSupportedLangList";
import getTranslatedAndVerifiedSetenceCount from "./Anuvaad/getTranslatedAndVerifiedSetenceCount";
import getCountByLang from "./Anuvaad/getCountByLang";
import getDocumentCountPerOrg from "./Anuvaad/getDocumentCountPerOrg";
import getTranscriptReport from "./Chitralekha/getTranscriptReport";
import getTranslationReport from "./Chitralekha/getTranslationReport";
import getVoiceoverReport from "./Chitralekha/getVoiceoverReport";
import getSupportedLanguages from "./Chitralekha/getSupportedLanguages";

const rootReducer = {
  apiStatus,
  getAnalyticsData,
  getMetaAnalyticsData,
  getDatasets,
  getModels,
  getDomainAndCollectionSourceData,
  getIndicGlossaryExplorerLanguageCountData,
  getAllLanguages,
  getAnuvaadSupportedLanguages,
  getTranslatedAndVerifiedSetenceCount,
  getCountByLang,
  getDocumentCountPerOrg,
  getTranscriptReport,
  getTranslationReport,
  getSupportedLanguages,
  getVoiceoverReport,
};

export default rootReducer;

import constants from "../../constants";

export default function (state = {}, action) {
  console.log(" action.payload ----- ", action);
    switch (action.type) {
        
        case constants.GET_COUNT_BY_LANG:
          return {
            ...state,
            data: action.payload,
          };

        default:
            return state;
    }
}
import { Grid } from "@material-ui/core";
import React from "react";
// import ShoonyaImg from "../../img/shoonya-bg.png";
import { Box, Button, Typography } from "@mui/material";
import AnalyticsChart from "./AnalyticsChart";
import GlobalStyles from "../../../../styles/GlobalStyles";

// import CustomBanner from "../components/common/CustomBanner";
import SemanticTextualSimilarityChart from "./SemanticTextualSimilarityChart";
import ContextualSentenceVerificationChart from "./ContextualSentenceVerificationChart";
import FetchAnalyticsDataAPI from "../../../../redux/actions/api/Shoonya/AnalyticsData";
import SingleSpeakerAudioTranscriptionEditing from "./SingleSpeakerAudioTranscriptionEditing";
import APITransport from "../../../../redux/actions/apitransport/apitransport";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import AudioTranscription from "./AudioTranscription";
import AcousticNormalisedTranscriptionEditing from "./AcousticNormalisedTranscriptionEditing";
import AudioSegmentation from "./AudioSegmentation";
import ContextualSentenceVerificationAndDomainClassification from "./ContextualSentenceVerificationAndDomainClassification";
import ConversationTranslation from "./ConversationTranslation";
import ConversationTranslationEditing from "./ConversationTranslationEditing";
import ConversationVerification from "./ConversationVerification";
import OCRTranscriptionEditing from "./OCRTranscriptionEditing";

const Shoonya = () => {
  const classes = GlobalStyles();
  const dispatch = useDispatch();
  const [loadingChart, setLoadingChart] = useState(true);

  const CumulativeTasksData = useSelector(
    (state) => state.getAnalyticsData.data
  );

  const getAnnotationdata = () => {
    setLoadingChart(true);
    const userObj = new FetchAnalyticsDataAPI();
    dispatch(APITransport(userObj));
  };

  useEffect(() => {
    getAnnotationdata();
  }, []);

  useEffect(() => {
    if (CumulativeTasksData.length > 0) {
      setLoadingChart(false);
    }
  }, [CumulativeTasksData]);
  return (
    <>
      {/* <CustomBanner
        heading={"Shoonya"}
        description={description}
        buttonGroupConfig={buttonGroup}
        imgSource={ShoonyaImg}
      /> */}
      <Grid style={{ marginTop: "15px" }}>
        <AnalyticsChart
          CumulativeTasksData={CumulativeTasksData}
          loadingChart={loadingChart}
        />
      </Grid>
      <Grid>
        <SingleSpeakerAudioTranscriptionEditing
          CumulativeTasksData={CumulativeTasksData}
          loadingChart={loadingChart}
        />
      </Grid>
      <Grid>
        <AudioTranscription
          CumulativeTasksData={CumulativeTasksData}
          loadingChart={loadingChart}
        />
      </Grid>
      <Grid>
        <AcousticNormalisedTranscriptionEditing
          CumulativeTasksData={CumulativeTasksData}
          loadingChart={loadingChart}
        />
      </Grid>
      <Grid>
        <AudioSegmentation
          CumulativeTasksData={CumulativeTasksData}
          loadingChart={loadingChart}
        />
      </Grid>
      <Grid>
        <ContextualSentenceVerificationAndDomainClassification
          CumulativeTasksData={CumulativeTasksData}
          loadingChart={loadingChart}
        />
      </Grid>
      <Grid>
        <ConversationTranslation
          CumulativeTasksData={CumulativeTasksData}
          loadingChart={loadingChart}
        />
      </Grid>
      <Grid>
        <ConversationTranslationEditing
          CumulativeTasksData={CumulativeTasksData}
          loadingChart={loadingChart}
        />
      </Grid>
      <Grid>
        <ConversationVerification
          CumulativeTasksData={CumulativeTasksData}
          loadingChart={loadingChart}
        />
      </Grid>
      <Grid>
        <OCRTranscriptionEditing
          CumulativeTasksData={CumulativeTasksData}
          loadingChart={loadingChart}
        />
      </Grid>
      <Grid>
        <SemanticTextualSimilarityChart
          CumulativeTasksData={CumulativeTasksData}
          loadingChart={loadingChart}
        />
      </Grid>
      <Grid>
        <ContextualSentenceVerificationChart
          CumulativeTasksData={CumulativeTasksData}
          loadingChart={loadingChart}
        />
      </Grid>
    </>
  );
};

export default Shoonya;

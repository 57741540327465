import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Typography,
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  XAxis,
  LabelList,
  YAxis,
  Tooltip,
  Label,
} from "recharts";
import FetchDatasetChartAPI from "../../../redux/actions/api/ULCA/FetchDatasetChart";
import ChartStyles from "../../../styles/ChartStyles";
import APITransport from "../../../redux/actions/apitransport/apitransport";
import { DatasetItems, Language } from "../../../utils/DatasetItem";
import { getParallelCorpusConfig } from "../../../utils/ulcaConfigs/ParallelCorpus";
import { getMonolingualCorpus } from "../../../utils/ulcaConfigs/MonolingualCorpus";
import { getAsrCorpus } from "../../../utils/ulcaConfigs/AsrCorpus";
import { getTtsCorpus } from "../../../utils/ulcaConfigs/TtsCorpus";
import { getOcrCorpus } from "../../../utils/ulcaConfigs/OcrCorpus";
import { getAsrUnlabeledCorpus } from "../../../utils/ulcaConfigs/AsrUnlabeledCorpus";
import { getTransliterationCorpus } from "../../../utils/ulcaConfigs/TransliterationCorpus";
import { getGlossaryCorpus } from "../../../utils/ulcaConfigs/GlossaryCorpus";
import CustomizedButton from "../common/CustomizedButton";
import getCommaSaparatedNumber from "../../../utils/getCommaSaparatedNumber";
import ResponsiveChartContainer from "../common/ResponsiveChartContainer";

const colors = [
  "188efc",
  "7a47a4",
  "b93e94",
  "1fc6a4",
  "f46154",
  "d088fd",
  "f3447d",
  "188efc",
  "f48734",
  "189ac9",
  "0e67bd",
];

const DatasetChart = () => {
  const dispatch = useDispatch();
  const classes = ChartStyles();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(DatasetItems[0]);
  const [axisValue, setAxisValue] = useState({
    yAxis: "Count",
    xAxis: "Languages",
  });
  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [toggleValue, setToggleValue] = useState("domains");
  const [title, setTitle] = useState(
    "Number of parallel sentences per language with"
  );
  const [filterValue, setFilterValue] = useState("domains");
  const [dataValue, setDataValue] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedLanguageName, setSelectedLanguageName] = useState("");
  const [page, setPage] = useState(0);
  const [sourceLanguage, setSourceLanguage] = useState({
    value: "en",
    label: "English",
  });

  const chartData = useSelector((state) => state.getDatasets.data);

  useEffect(() => {
    chartData?.data?.sort((a, b) => b.value - a.value);
    setData(chartData.data);
    let total = chartData?.data?.reduce(
      (acc, rem) => (acc = acc + Number(rem.value)),
      0
    );
    setCount(total % 1 === 0 ? total : total?.toFixed(3));
    setTotal(chartData.count);
    setLoading(false);
  }, [chartData]);

  const fetchChartData = (dataType, value, criterions) => {
    setLoading(true);
    let apiObj = new FetchDatasetChartAPI(dataType, value, criterions);
    dispatch(APITransport(apiObj));
  };

  useEffect(() => {
    fetchChartData(selectedOption.value, "", [
      { field: "sourceLanguage", value: sourceLanguage.value },
    ]);
  }, [selectedOption, sourceLanguage]);

  const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {payload.value &&
            payload.value.substr(0, 14) +
              (payload.value.length > 14 ? "..." : "")}
        </text>
      </g>
    );
  };

  const handleChange = (event) => {
    const obj = {};

    obj.value = event.target.value;
    obj.label = DatasetItems.reduce(
      (acc, rem) => (rem.value === event ? (acc = rem.label) : acc),
      ""
    );

    handleSelectChange(obj, "", "", page);
  };

  const handleSelectChange = (dataSet, event, filter, page) => {
    setSelectedOption(dataSet);

    switch (dataSet.value) {
      case "parallel-corpus":
        getParallelCorpusConfig(
          page,
          selectedOption,
          dataSet,
          fetchChartData,
          setTitle,
          setAxisValue,
          sourceLanguage,
          event,
          filter,
          selectedLanguageName,
          dataValue,
          filterValue
        );
        break;

      case "monolingual-corpus":
        getMonolingualCorpus(
          page,
          selectedOption,
          dataSet,
          fetchChartData,
          setTitle,
          setAxisValue,
          sourceLanguage,
          event,
          filter,
          selectedLanguageName,
          dataValue,
          filterValue
        );
        break;

      case "asr-corpus":
        getAsrCorpus(
          page,
          selectedOption,
          dataSet,
          fetchChartData,
          setTitle,
          setAxisValue,
          sourceLanguage,
          event,
          filter,
          selectedLanguageName,
          dataValue,
          filterValue
        );
        break;

      case "tts-corpus":
        getTtsCorpus(
          page,
          selectedOption,
          dataSet,
          fetchChartData,
          setTitle,
          setAxisValue,
          sourceLanguage,
          event,
          filter,
          selectedLanguageName,
          dataValue,
          filterValue
        );
        break;

      case "ocr-corpus":
        getOcrCorpus(
          page,
          selectedOption,
          dataSet,
          fetchChartData,
          setTitle,
          setAxisValue,
          sourceLanguage,
          event,
          filter,
          selectedLanguageName,
          dataValue,
          filterValue
        );
        break;

      case "asr-unlabeled-corpus":
        getAsrUnlabeledCorpus(
          page,
          selectedOption,
          dataSet,
          fetchChartData,
          setTitle,
          setAxisValue,
          sourceLanguage,
          event,
          filter,
          selectedLanguageName,
          dataValue,
          filterValue
        );
        break;

      case "transliteration-corpus":
        getTransliterationCorpus(
          page,
          selectedOption,
          dataSet,
          fetchChartData,
          setTitle,
          setAxisValue,
          sourceLanguage,
          event,
          filter,
          selectedLanguageName,
          dataValue,
          filterValue
        );
        break;

      case "glossary-corpus":
        getGlossaryCorpus(
          page,
          selectedOption,
          dataSet,
          fetchChartData,
          setTitle,
          setAxisValue,
          sourceLanguage,
          event,
          filter,
          selectedLanguageName,
          dataValue,
          filterValue
        );
        break;

      default:
        setTitle("");
    }
  };

  const handleLanguagePairChange = (value, property) => {
    let sLang = Language.filter((val) => val.label === value)[0];
    if (sLang) {
      setSourceLanguage(sLang);
    }
  };

  const renderTexfield = (id, label, value, options, filter) => {
    let labels = Language.map((lang) => lang.label);
    return (
      <Autocomplete
        className={classes.titleDropdown}
        value={sourceLanguage.label}
        id="source"
        options={labels}
        onChange={(event, data) => handleLanguagePairChange(data, "source")}
        renderInput={(params) => (
          <TextField fullWidth {...params} variant="standard" />
        )}
      />
    );
  };

  const fetchParams = (event) => {
    var source = "";
    let targetLanguage = "";
    if (
      selectedOption.value === "transliteration-corpus" ||
      selectedOption.value === "parallel-corpus"
    ) {
      source = sourceLanguage.value;
      targetLanguage = selectedLanguage
        ? selectedLanguage
        : event && event.hasOwnProperty("_id") && event._id;
    } else {
      source = selectedLanguage
        ? selectedLanguage
        : event && event.hasOwnProperty("_id") && event._id;
      targetLanguage = "";
    }
    setSelectedLanguage(
      selectedLanguage
        ? selectedLanguage
        : event && event.hasOwnProperty("_id") && event._id
    );
    setSelectedLanguageName(
      selectedLanguageName
        ? selectedLanguageName
        : event && event.hasOwnProperty("label") && event.label
    );
    if (
      selectedOption.value === "transliteration-corpus" ||
      selectedOption.value === "parallel-corpus"
    ) {
      return [
        { field: "sourceLanguage", value: source },
        { field: "targetLanguage", value: targetLanguage },
      ];
    } else if (selectedOption.value === "glossary-corpus") {
      return [
        { field: "sourceLanguage", value: "en" },
        { field: "targetLanguage", value: source },
      ];
    } else {
      return [
        { field: "sourceLanguage", value: source },
        { field: "targetLanguage", value: targetLanguage },
      ];
    }
  };

  const fetchNextParams = (eventValue) => {
    var source = "";
    let targetLanguage = "";
    let val = eventValue && eventValue.hasOwnProperty("_id") && eventValue._id;
    let event = { field: filterValue, value: val ? val : dataValue };
    val && setDataValue(val);
    if (selectedOption.value === "parallel-corpus") {
      source = sourceLanguage.value;
      targetLanguage = selectedLanguage;
    } else {
      source = selectedLanguage;
      targetLanguage = "";
    }
    setSelectedLanguage(
      selectedLanguage
        ? selectedLanguage
        : event && event.hasOwnProperty("_id") && event._id
    );
    setSelectedLanguageName(
      selectedLanguageName
        ? selectedLanguageName
        : event && event.hasOwnProperty("label") && event.label
    );
    if (
      selectedOption.value === "transliteration-corpus" ||
      selectedOption.value === "parallel-corpus"
    ) {
      return [
        { field: "sourceLanguage", value: source },
        { field: "targetLanguage", value: targetLanguage },
        event,
      ];
    } else if (selectedOption.value === "glossary-corpus") {
      return [
        { field: "sourceLanguage", value: "en" },
        { field: "targetLanguage", value: source },
        event,
      ];
    } else {
      return [
        { field: "sourceLanguage", value: source },
        { field: "targetLanguage", value: "" },
        event,
      ];
    }
  };

  const handleOnClick = (value, event, filter) => {
    switch (value) {
      case 1:
        fetchChartData(
          selectedOption.value,
          filter ? filter : filterValue,
          fetchParams(event)
        );
        setFilterValue(filter ? filter : filterValue);
        handleSelectChange(selectedOption, event, filter, value);
        setPage(value);

        break;
      case 2:
        let fValue = filter
          ? filter
          : filterValue === "collectionMethod_collectionDescriptions"
          ? "domains"
          : "collectionMethod_collectionDescriptions";
        fetchChartData(selectedOption.value, fValue, fetchNextParams(event));
        setPage(value);

        setToggleValue(fValue);
        handleSelectChange(selectedOption, event, fValue, value);

        break;
      case 0:
        fetchChartData(selectedOption.value, "", [
          { field: "sourceLanguage", value: sourceLanguage.value },
        ]);
        setPage(value);
        setFilterValue("domains");
        handleSelectChange(selectedOption, "", "", value);
        setSelectedLanguage("");
        setSelectedLanguageName("");
        break;
      default:
    }
  };

  const handleLanguageChange = (value) => {
    setFilterValue(value);
    setTitle(
      `English-${selectedLanguageName}  ${selectedOption.value}- Grouped by ${
        value === "domains"
          ? "Domain"
          : value === "source"
          ? "Source"
          : value === "collectionMethod_collectionDescriptions"
          ? "Collection Method"
          : "Domain"
      }`
    );
    handleOnClick(1, "", value);
  };

  const handleLevelChange = (value) => {
    setToggleValue(value);
    handleOnClick(2, "", value);
  };

  const fetchFilterButtons = () => {
    return (
      <div className={classes.filterButton}>
        <Typography className={classes.fiterText} value="" variant="body1">
          {" "}
          Filter By{" "}
        </Typography>
        <Button
          size="small"
          variant="outlined"
          className={classes.backButton}
          onClick={() => handleLanguageChange("domains")}
        >
          Domain
        </Button>
        <Button
          style={{ marginRight: "10px" }}
          size="small"
          variant="outlined"
          onClick={() =>
            handleLanguageChange("collectionMethod_collectionDescriptions")
          }
          className={classes.backButton}
        >
          Collection Method
        </Button>
      </div>
    );
  };

  const fetchButtonssecondLevel = () => {
    return (
      <div className={classes.filterButton}>
        <Typography className={classes.fiterText} value="" variant="body1">
          {" "}
          Filter By{" "}
        </Typography>
        {filterValue !== "domains" && (
          <Button
            size="small"
            variant="outlined"
            className={classes.backButton}
            onClick={() => handleLevelChange("domains")}
          >
            Domain
          </Button>
        )}
        {filterValue !== "collectionMethod_collectionDescriptions" && (
          <Button
            style={{ marginRight: "10px" }}
            size="small"
            variant="outlined"
            className={classes.backButton}
            onClick={() =>
              handleLevelChange("collectionMethod_collectionDescriptions")
            }
          >
            Collection Method
          </Button>
        )}
      </div>
    );
  };

  return (
    <Box className={classes.chartSection}>
      <Box className={classes.heading}>
        <Typography variant="h2">Dataset Dashboard</Typography>
        <Typography variant="body1">
          Dataset contributions from the DMU (Data Management Unit) to ULCA
        </Typography>
      </Box>
      <Paper>
        <Box className={classes.topBar}>
          <Box className={classes.topBarInnerBox}>
            <Select
              value={selectedOption.value}
              onChange={handleChange}
              sx={{ ".MuiOutlinedInput-notchedOutline": { border: 0 } }}
            >
              {DatasetItems.map((item) => {
                return <MenuItem value={item.value}>{item.label}</MenuItem>;
              })}
            </Select>
          </Box>
          <Box className={classes.topBarInnerBox}>
            <Typography style={{ fontSize: "0.875rem", fontWeight: "400" }}>
              Total Count
            </Typography>
            <Typography style={{ fontSize: "1.125rem", fontWeight: "400" }}>
              {getCommaSaparatedNumber(total)}
            </Typography>
          </Box>
          <Box style={{ width: "100%" }}>
            {page === 1
              ? fetchFilterButtons()
              : page === 2
              ? fetchButtonssecondLevel()
              : ""}
          </Box>
        </Box>
        <CustomizedButton
          size="small"
          color="primary"
          sx={{
            visibility: page === 0 ? "hidden" : "",
            margin: "10px 0 0 20px",
          }}
          title="Reset"
          onClick={() => handleOnClick(0)}
        >
          Reset
        </CustomizedButton>
        <Box>
          {selectedOption.value === "parallel-corpus" ||
          (selectedOption.value === "transliteration-corpus" && page === 0) ? (
            <div className={classes.titleStyle}>
              <Typography className={classes.titleText} value="" variant="h6">
                {" "}
                {title}{" "}
              </Typography>
              <div className={classes.dropDownStyle}>
                {renderTexfield("select-source-language", "Source Language *")}
                <Typography
                  value=""
                  variant="h6"
                  style={{ marginLeft: "10px" }}
                >
                  ({count ? new Intl.NumberFormat("en").format(count) : 0})
                </Typography>
              </div>
            </div>
          ) : (
            <Typography className={classes.titleText} value="" variant="h6">
              {" "}
              {title}{" "}
              <span>
                ({count ? new Intl.NumberFormat("en").format(count) : 0})
              </span>
            </Typography>
          )}
        </Box>
        <Box style={{ margin: "20px" }}>
        {!loading ? (<ResponsiveChartContainer>
            <BarChart
              width={900}
              height={400}
              data={data}
              fontSize="14px"
              fontFamily="Roboto"
              maxBarSize={100}
            >
              <XAxis
                dataKey="label"
                textAnchor={"end"}
                tick={<CustomizedAxisTick />}
                height={100}
                interval={0}
                position="insideLeft"
                type="category"
              >
                <Label
                  value={axisValue.xAxis}
                  position="insideBottom"
                  fontWeight="bold"
                  fontSize={16}
                ></Label>
              </XAxis>
              <YAxis
                padding={{ top: 80 }}
                tickInterval={10}
                allowDecimals={false}
                type="number"
                dx={0}
                tickFormatter={(value) =>
                  new Intl.NumberFormat("en", { notation: "compact" }).format(
                    value
                  )
                }
              >
                <Label
                  value={axisValue.yAxis}
                  angle={-90}
                  position="insideLeft"
                  fontWeight="bold"
                  fontSize={16}
                ></Label>
              </YAxis>

              <Tooltip
                contentStyle={{ fontFamily: "Roboto", fontSize: "14px" }}
                formatter={(value) => new Intl.NumberFormat("en").format(value)}
                cursor={{ fill: "none" }}
              />
              <Bar
                margin={{ top: 140, left: 20, right: 20, bottom: 20 }}
                dataKey="value"
                cursor="pointer"
                radius={[8, 8, 0, 0]}
                maxBarSize={65}
                onClick={(event) => {
                  handleOnClick(page + 1, event);
                }}
                isAnimationActive={false}
              >
                <LabelList
                  formatter={(value) =>
                    new Intl.NumberFormat("en").format(value)
                  }
                  cursor={{ fill: "none" }}
                  position="top"
                  dataKey="value"
                  fill="black"
                  style={{ textAnchor: "start" }}
                  angle={-30}
                  clockWise={4}
                />
                {data?.length > 0 &&
                  data?.map((entry, index) => {
                    const color = colors[index < 9 ? index : index % 10];
                    return <Cell key={index} fill={`#${color}`} />;
                  })}
              </Bar>
            </BarChart>
          </ResponsiveChartContainer>) : (
                        <Box display="flex" justifyContent="center">
                            <CircularProgress
                                color="primary"
                                size={50}
                                style={{ margin: "20%" }}
                            />
                        </Box>
                    )}
        </Box>
      </Paper>
    </Box>
  );
};

export default DatasetChart;

import C from "../../constants";

let initialState = {
  translationLanguages: [],
  voiceoverLanguages: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case C.GET_TRANSLATION_SUPPORTED_LANGUAGES:
      return {
        ...state,
        translationLanguages: action.payload,
      };

    case C.GET_VOICEOVER_SUPPORTED_LANGUAGES:
      return {
        ...state,
        voiceoverLanguages: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default reducer;

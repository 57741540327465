import React from "react";
import CustomizedButton from "./CustomizedButton";

const CustomButtonGroup = ({ buttonGroup }) => {
  return (
    <>
      {buttonGroup.map((item) => {
        return (
          <CustomizedButton
            title={item.title}
            className={item.className}
            onClick={item.onClick}
          />
        );
      })}
    </>
  );
};

export default CustomButtonGroup;

import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  XAxis,
  LabelList,
  YAxis,
  Tooltip,
  Label,
} from "recharts";
import FetchModelChartAPI from "../../../redux/actions/api/ULCA/FetchModelChart";
import ChartStyles from "../../../styles/ChartStyles";
import APITransport from "../../../redux/actions/apitransport/apitransport";
import CustomizedButton from "../common/CustomizedButton";
import getCommaSaparatedNumber from "../../../utils/getCommaSaparatedNumber";
import ResponsiveChartContainer from "../common/ResponsiveChartContainer";

const colors = [
  "188efc",
  "7a47a4",
  "b93e94",
  "1fc6a4",
  "f46154",
  "d088fd",
  "f3447d",
  "188efc",
  "f48734",
  "189ac9",
  "0e67bd",
];

const ModelChart = () => {
  const dispatch = useDispatch();
  const classes = ChartStyles();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [selectedType, setSelectedType] = useState("");
  const [selectedTypeName, setSelectedTypeName] = useState("");
  const [axisValue, setAxisValue] = useState({
    yAxis: "Count",
    xAxis: "Task",
  });

  const chartData = useSelector((state) => state.getModels.data);

  const fetchChartData = (dataType, criterions, value) => {
    setLoading(true);
    let apiObj = new FetchModelChartAPI(dataType, criterions, value);
    dispatch(APITransport(apiObj));
  };

  useEffect(() => {
    fetchChartData("model", "", "");
  }, []);

  useEffect(() => {
    chartData?.data?.sort((a, b) => b.value - a.value);
    setData(chartData.data);
    setCount(chartData.count);
    setLoading(false);
  }, [chartData]);

  const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {payload.value &&
            payload.value.substr(0, 14) +
              (payload.value.length > 14 ? "..." : "")}
        </text>
      </g>
    );
  };

  const fetchCriterions = (event) => {
    setSelectedType(
      selectedType
        ? selectedType
        : event && event.hasOwnProperty("_id") && event._id
    );
    setSelectedTypeName(
      selectedTypeName
        ? selectedTypeName
        : event && event.hasOwnProperty("label") && event.label
    );
    return [
      {
        field: "type",
        value:
          event && event.hasOwnProperty("_id") && event._id
            ? event._id
            : selectedType,
      },
    ];
  };

  const handleOnClick = (value, event) => {
    switch (value) {
      case 1:
        fetchChartData("model", fetchCriterions(event), "language");
        setPage(value);
        setAxisValue({ xAxis: "Languages", yAxis: "Count" });
        break;

      case 0:
        fetchChartData("model", "", "");
        setPage(value);
        setSelectedType("");
        setSelectedTypeName("");
        setAxisValue({ xAxis: "Task", yAxis: "Count" });
        break;
      default:
    }
  };

  return (
    <Box className={classes.modelChartSection}>
      <Box className={classes.heading}>
        <Typography variant="h2">
          Model Dashboard
        </Typography>
        <Typography variant="body1">
          Models contributions from the DMU (Data Management Unit) to ULCA
        </Typography>
      </Box>
      <Paper>
        <Box className={classes.topBar}>
          <Box className={classes.topBarInnerBox}>
            <Typography
              style={{ fontSize: "1rem", fontWeight: "600", padding: "16px 0" }}
            >
              Model
            </Typography>
          </Box>
          <Box className={classes.topBarInnerBox}>
            <Typography style={{ fontSize: "0.875rem", fontWeight: "400" }}>
              Total Count
            </Typography>
            <Typography style={{ fontSize: "1.125rem", fontWeight: "400" }}>
              {getCommaSaparatedNumber(count)}
            </Typography>
          </Box>
        </Box>
        <CustomizedButton
          title="Reset"
          size="small"
          color="primary"
          sx={{
            visibility: page === 0 ? "hidden" : "",
            margin: "10px 0 0 20px",
          }}
          onClick={() => handleOnClick(0)}
        />

        <Box style={{ margin: "20px" }}>
          {!loading ? (<ResponsiveChartContainer>
            <BarChart
              width={900}
              height={400}
              data={data}
              fontSize="14px"
              fontFamily="Roboto"
              maxBarSize={100}
            >
              <XAxis
                dataKey="label"
                textAnchor={"end"}
                tick={<CustomizedAxisTick />}
                height={100}
                interval={0}
                position="insideLeft"
                type="category"
              >
                <Label
                  value={axisValue.xAxis}
                  position="insideBottom"
                  fontWeight="bold"
                  fontSize={16}
                ></Label>
              </XAxis>
              <YAxis
                padding={{ top: 80 }}
                tickInterval={10}
                allowDecimals={false}
                type="number"
                dx={0}
                tickFormatter={(value) =>
                  new Intl.NumberFormat("en", { notation: "compact" }).format(
                    value
                  )
                }
              >
                <Label
                  value={axisValue.yAxis}
                  angle={-90}
                  position="insideLeft"
                  fontWeight="bold"
                  fontSize={16}
                ></Label>
              </YAxis>

              <Tooltip
                contentStyle={{ fontFamily: "Roboto", fontSize: "14px" }}
                formatter={(value) => new Intl.NumberFormat("en").format(value)}
                cursor={{ fill: "none" }}
              />
              <Bar
                margin={{ top: 140, left: 20, right: 20, bottom: 20 }}
                dataKey="value"
                cursor="pointer"
                radius={[8, 8, 0, 0]}
                maxBarSize={65}
                onClick={(event) => handleOnClick(page + 1, event)}
                isAnimationActive={false}
              >
                <LabelList
                  formatter={(value) =>
                    new Intl.NumberFormat("en").format(value)
                  }
                  cursor={{ fill: "none" }}
                  position="top"
                  dataKey="value"
                  fill="black"
                  style={{ textAnchor: "start" }}
                  angle={-30}
                  clockWise={4}
                />
                {data?.length > 0 &&
                  data?.map((entry, index) => {
                    const color = colors[index < 9 ? index : index % 10];
                    return <Cell key={index} fill={`#${color}`} />;
                  })}
              </Bar>
            </BarChart>
          </ResponsiveChartContainer>) : (
                        <Box display="flex" justifyContent="center">
                            <CircularProgress
                                color="primary"
                                size={50}
                                style={{ margin: "20%" }}
                            />
                        </Box>
                    )}
        </Box>
      </Paper>
    </Box>
  );
};

export default ModelChart;

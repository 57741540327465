import React from "react";
import HomeImg from "../../img/home-bg.png";
import {
  Box,
  Typography,
  List,
  ListItem,
} from "@mui/material";
import GlobalStyles from "../../styles/GlobalStyles";
import DatasetChart from "../components/ULCA/DatasetChart";
import ModelChart from "../components/ULCA/ModelChart";

const Home = () => {
  const classes = GlobalStyles();
  return (
    <>
      <Box display={{xs: 'block', md: 'none'}} sx={{textAlign: 'center', marginBottom: -2, marginTop: 1}}>
        <img className="aboutImg" style={{width: '100%'}} src={HomeImg} />
      </Box>
      <Box className={classes.section} marginTop= {{xs: 2, md: 0}}>
        <Box display={{xs: 'none', md: 'inherit'}}>
          <img className="aboutImg" src={HomeImg} />
        </Box>
        <Box className={classes.textBox}>
          <Typography className={classes.homeDescription}>
            AI based tools that leverage the ML models.
          </Typography>
          <Typography className={classes.homeDescription}>
            Bring parity with respect to English in AI technologies for Indian
            languages with open source countributions in datasets, models and
            applications and by enabling an innovation ecosystem.
          </Typography>
          <Typography className={classes.homeDescription}>
            AI4Bharat is the Data Management Unit (DMU) for the National
            Language Translation Mission (NLTM), an initiative by the Ministry
            of Electronics and Information Technology (MeitY), Government of
            India.
          </Typography>
          <Typography className={classes.homeDescription}>
            The main goals of the DMU are :
            <List className={classes.list}>
              <ListItem className={classes.listItem}>
                Generate various kinds of datasets
              </ListItem>
              <ListItem className={classes.listItem}> Develop models </ListItem>
              <ListItem className={classes.listItem}>
                Create necessary AI based tools to achieve these.
              </ListItem>
            </List>
          </Typography>
        </Box>
      </Box>
      <DatasetChart />
      <ModelChart />
    </>
  );
};

export default Home;

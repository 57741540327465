import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AnuvaadImg from "../../img/anuvaad-bg.png";
import GlobalStyles from "../../styles/GlobalStyles";
import CustomBanner from "../components/common/CustomBanner";
import APITransport from "../../redux/actions/apitransport/apitransport";
import getAnuvaadSupportedLanguages from "../../redux/actions/api/Anuvaad/getSupportedLangList";
import getAnuvaadTranslatedAndVerifiedSetenceCount from "../../redux/actions/api/Anuvaad/getTranslatedAndVerifiedSetenceCount";
import getAnuvaadCountByLang from "../../redux/actions/api/Anuvaad/getCountByLang";
import DocumentCountByLang from "../components/Anuvaad/DocumentCountByLang";
import TranslatedAndVarifiedSentenceByLang from "../components/Anuvaad/TranslatedAndVarifiedSentenceByLang"
import getAnuvaadDocumentCountPerOrg from "../../redux/actions/api/Anuvaad/getDocumentCountPerOrg";
import DocumentCountByOrg from "../components/Anuvaad/DocumentCountByOrg";

const Anuvaad = () => {
  const classes = GlobalStyles();
  const dispatch = useDispatch();

  const [loadingDocumentCountByLangChart, setLoadingDocumentCountByLangChart] = useState(false);
  const [loadingDocumentCountByOrgChart, setLoadingDocumentCountByOrgChart] = useState(false);

  const documentCountByLang = useSelector(state=> state.getCountByLang?.data?.data);

  const documentCountByOrg = useSelector(state=>state.getDocumentCountPerOrg.data?.data);

  const getSupportedLangList = () => {
    const apiObj = new getAnuvaadSupportedLanguages();
    dispatch(APITransport(apiObj));
  }

  const getTranslatedAndVerifiedSetenceCount = () => {
    const apiObj = new getAnuvaadTranslatedAndVerifiedSetenceCount();
    dispatch(APITransport(apiObj));
  }

  const getCountByLang = (srcLang = "en") => {
    setLoadingDocumentCountByLangChart(true);
    const apiObj = new getAnuvaadCountByLang(srcLang);
    dispatch(APITransport(apiObj));
  }

  const getDocumentCountPerOrg = () => {
    
    const apiObj = new getAnuvaadDocumentCountPerOrg();
    dispatch(APITransport(apiObj));
  }

  useEffect(() => {
    setLoadingDocumentCountByLangChart(true);
    setLoadingDocumentCountByOrgChart(true);
    getSupportedLangList();
    getTranslatedAndVerifiedSetenceCount();
    getCountByLang();
    getDocumentCountPerOrg()
  }, [])

  useEffect(()=>{
    setLoadingDocumentCountByLangChart(false);
  }, [documentCountByLang])

  useEffect(()=>{
    setLoadingDocumentCountByOrgChart(false);
  }, [documentCountByOrg])

  const buttonGroup = [
    {
      title: "Try Demo",
      className: classes.demoBtn,
      onClick: () => window.open("https://users.anuvaad.org/", "_blank"),
    },
    {
      title: "Codebase",
      className: classes.demoBtn,
      onClick: () =>
        window.open("https://github.com/project-anuvaad/anuvaad", "_blank"),
    },
    {
      title: "Tutorial",
      className: classes.demoBtn,
      onClick: () => window.open("https://anuvaad.sunbird.org/", "_blank"),
    },
    {
      title: "Introduction Video",
      className: classes.demoBtn,
      onClick: () =>
        window.open("https://www.youtube.com/watch?v=Y2pBaME4qiY", "_blank"),
    },
    {
      title: "API Specs",
      className: classes.demoBtn,
      onClick: () =>
        window.open(
          "https://app.swaggerhub.com/apis/Anuvaad/AnuvaadApi/1.0.0",
          "_blank"
        ),
    },
  ];

  const description = (
    <>
      <b>Anuvaad</b> is an AI based open source{" "}
      <b>Document Translation Platform</b> to translate documents in Indic
      languages at scale. Anuvaad provides easy-to-edit capabilities on top the
      plug & play NMT models. Separate instances of Anuvaad are deployed to{" "}
      <a
        className={classes.homeLink}
        href="https://diksha.anuvaad.org/"
        target="blank"
      >
        <b>Diksha</b>
      </a>{" "}
      (NCERT),{" "}
      <a
        className={classes.homeLink}
        href="https://jud.anuvaad.org/"
        target="blank"
      >
        <b>Supreme Court of India</b>{" "}
      </a>{" "}
      (SUVAS) and{" "}
      <b className={classes.homeLink}>Supreme Court of Bangladesh</b> (Amar
      Vasha).
    </>
  );

  return (
    <>
      <CustomBanner
        heading={"Anuvaad"}
        description={description}
        buttonGroupConfig={buttonGroup}
        imgSource={AnuvaadImg}
      />
      <DocumentCountByLang incomingData={documentCountByLang} loadingChart={loadingDocumentCountByLangChart} onLanguageChange={getCountByLang} />
      <TranslatedAndVarifiedSentenceByLang />
      <DocumentCountByOrg sourceData={documentCountByOrg} loadingChart={loadingDocumentCountByOrgChart} />
    </>


  );
};

export default Anuvaad;

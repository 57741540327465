const configs = {
  BASE_URL: "https://backend.shoonya.ai4bharat.org",
  BASE_URL_AUTO: process.env.REACT_APP_APIGW_BASE_URL
    ? process.env.REACT_APP_APIGW_BASE_URL
      :"https://backend.shoonya.ai4bharat.org",
  BASE_URL_ULCA: "https://meity-auth.ulcacontrib.org/",
  BASE_URL_INDIC_GLOSSARY_EXPLORER: "https://glossary-api.ai4bharat.org/",
  BASE_URL_ANUVAAD: "https://users.anuvaad.org/",
  BASE_URL_CHITRALEKHA: "https://backend.chitralekha.ai4bharat.org/",
};

export default configs;
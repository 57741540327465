const constants = {
    APISTATUS: "APISTATUS",

    //SHOONYA
    FETCH_ANALYTICS_DATA:"FETCH_ANALYTICS_DATA",
    FETCH_META_ANALYTICS_DATA:"FETCH_META_ANALYTICS_DATA",

    //ULCA
    FETCH_DATASET: "FETCH_DATASET",
    FETCH_MODEL: "FETCH_MODEL",

    // INDIC GLOSSARY EXPLORER
    FETCH_DOMAIN_AND_COLLECTION_SOURCE_DATA: "FETCH_DOMAIN_AND_COLLECTION_SOURCE_DATA",
    FETCH_LANGUAGE_COUNT_DATA: "FETCH_LANGUAGE_COUNT_DATA",
    GET_ALL_LANGUAGE: "GET_ALL_LANGUAGE",

    // ANUVAAD
    GET_ALL_SUPPORTED_LANG_LIST: "GET_ALL_SUPPORTED_LANG_LIST",
    GET_TRANSLATED_AND_VERIFIED_SETENCE_COUNT: "GET_TRANSLATED_AND_VERIFIED_SETENCE_COUNT",
    GET_COUNT_BY_LANG: "GET_COUNT_BY_LANG",
    GET_DOCUMENT_COUNT_PER_ORG: "GET_DOCUMENT_COUNT_PER_ORG",

    //CHITRALEKHA
    GET_TRANSLATION_REPORT: "GET_TRANSLATION_REPORT", 
    GET_TRANSCRIPT_REPORT: "GET_TRANSCRIPT_REPORT",
    GET_TRANSLATION_SUPPORTED_LANGUAGES: "GET_TRANSLATION_SUPPORTED_LANGUAGES",
    GET_VOICEOVER_SUPPORTED_LANGUAGES: "GET_VOICEOVER_SUPPORTED_LANGUAGES",
    GET_VOICEOVER_REPORT: "GET_VOICEOVER_REPORT",
};

export default constants;
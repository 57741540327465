import { Grid } from "@material-ui/core";
import React from "react";
import ShoonyaImg from "../../img/shoonya-bg.png";
import { Box, Button, Typography,Tab,Tabs } from "@mui/material";
import GlobalStyles from "../../styles/GlobalStyles";
import CustomBanner from "../components/common/CustomBanner";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import TaskAnalytics from "../components/Shoonya/TaskAnalytics/TaskAnalytics";
import MetaAnalytics from "../components/Shoonya/MetaAnalytics/MetaAnalytics";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
          {value === index && (
              <Box p={2}>
                  <Typography>{children}</Typography>
              </Box>
          )}
      </div>
  );
}

const Shoonya = () => {
  const classes = GlobalStyles();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (e, v) => {
      setTabValue(v)
  }
  



  const buttonGroup = [
    {
      title: "Try Demo",
      className: classes.demoBtn,
      onClick: () =>
        window.open("https://demo.shoonya.ai4bharat.org/", "_blank"),
    },
    {
      title: "Codebase",
      className: classes.demoBtn,
      onClick: () =>
        window.open("https://github.com/AI4Bharat/Shoonya", "_blank"),
    },
    {
      title: "Tutorial",
      className: classes.demoBtn,
      onClick: () =>
        window.open("https://github.com/AI4Bharat/Shoonya/wiki", "_blank"),
    },
    {
      title: "Introduction Video",
      className: classes.demoBtn,
      onClick: () =>
        window.open("https://www.youtube.com/watch?v=N4PBSB2fQto", "_blank"),
    },
    {
      title: "API Specs",
      className: classes.demoBtn,
      onClick: () =>
        window.open("https://backend.shoonya.ai4bharat.org/swagger/", "_blank"),
    },
  ];

  const description = (
    <>
      <b>Shoonya</b> is an <b>open source</b> platform to improve the efficiency
      of language work in Indian languages with AI tools and custom built UI
      interfaces and features. This is a key requirement to create larger
      dataset for training datasets for training AI models such as neural
      machine translation for a large number of Indian languages.
    </>
  );

  return (
    <>
      <CustomBanner
        heading={"Shoonya"}
        description={description}
        buttonGroupConfig={buttonGroup}
        imgSource={ShoonyaImg}
      />
        < >
            <Box sx={{mt:3,ml:3}} >
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="user-tabs">
                    <Tab label="Task Analytics " sx={{ fontSize: 17, fontWeight: '700', marginRight: '28px !important' }} />
                    {/* <Tab label="Meta Analytics " sx={{ fontSize: 17, fontWeight: '700', marginRight: '28px !important' }} /> */}
                </Tabs>
            </Box>
            <Box sx={{ p: 1}}>
                <TabPanel value={tabValue} index={0}>
                <TaskAnalytics  />  
                </TabPanel> 
                {/* <TabPanel value={tabValue} index={1}>
                <MetaAnalytics  />  
                </TabPanel>      */}
            </Box>
        </>
    
      
    </>
  );
};

export default Shoonya;

export const DatasetItems = [
  { value: "parallel-corpus", label: "Parallel Dataset" },
  { value: "monolingual-corpus", label: "Monolingual Dataset" },
  { value: "asr-corpus", label: "ASR Dataset" },
  { value: "tts-corpus", label: "TTS Dataset" },
  { value: "ocr-corpus", label: "OCR Dataset" },
  { value: "asr-unlabeled-corpus", label: "ASR Unlabeled Dataset" },
  { value: "transliteration-corpus", label: "Transliteration Dataset" },
  { value: "glossary-corpus", label: "Glossary Dataset" },
];

export const Language = [
  { value: "as", label: "Assamese" },
  { value: "bn", label: "Bengali" },
  { value: "en", label: "English" },
  { value: "gu", label: "Gujarati" },
  { value: "hi", label: "Hindi" },
  { value: "kn", label: "Kannada" },
  { value: "ml", label: "Malayalam" },
  { value: "mr", label: "Marathi" },
  { value: "or", label: "Odia" },
  { value: "pa", label: "Punjabi" },
  { value: "ta", label: "Tamil" },
  { value: "te", label: "Telugu" },
  { value: "ur", label: "Urdu" },
];
